import $ from 'jquery';
import 'jquery-match-height';
import 'whatwg-fetch';
import Cookies from 'js-cookie';

window.onload = function () {
	$(async function () {

		// Navigation
		$('.navbar-burger[data-target]').each(function () {
			var $this = $(this);
			var $toggles = $this.add('#' + $this.data('target'));

			$this.click(function () {
				$toggles.toggleClass('is-active');
			});
		});

		// Set same height
		$('.card-content').matchHeight({
			property: 'height'
		});

		// Slideshow
		$('.swiper-container').each(async function () {
			if ($(this).find('.swiper-slide').length <= 1) return;

			const Swiper = (await import(/* webpackChunkName: "swiper" */ './swiper.js')).default;

			new Swiper(this, {
				grabCursor: true,
				slidesPerView: 1,
				spaceBetween: 0,
				loop: true,
				autoplay: {
					delay: 3000
				},
				speed: 1500,
				pagination: {
					el: '.swiper-pagination',
					clickable: true
				}
			});
		});

		// Filter form
		$('form.filter').each(function () {
			var $form = $(this),
				update = $form.data('update');

			var currentRequest = null,
				sendRequest = function (url, type = 'get', data = null) {
					currentRequest = $.ajax({
						type: type,
						url: url,
						data: data,
						beforeSend: function () {
							if (currentRequest !== null) {
								currentRequest.abort();
							}

							$(update).addClass('loading');
						},
						success: async function (data) {
							var $data = $(data);
							$(update).replaceWith($data.find(update));

							if (window.history) {
								window.history.replaceState(null, null, this.url);
							}

							$('.card-content').matchHeight({
								property: 'height'
							});
						}
					});
				};

			$form.submit(function (e) {
				sendRequest($form.attr('action'), $form.attr('method'), $form.serialize().replace(/&?[^=&]+=(&|$)/g, ''));
				e.preventDefault();
			});

			$form.on('reset', function () {
				sendRequest($form.attr('action'), $form.attr('method'));
			});

			$form.find('select, input[type=checkbox], input[type=radio]').change(function () {
				// Enable auto-submit
				$form.submit();
			});

			// Hide submit
			$form.find('[type=submit]:not(.is-search), [type=reset]').toggleClass('is-hidden');
		});

		// Paged news messages
		$('.paged-news-messages').each(async function () {
			await import(/* webpackChunkName: "waypoints" */ 'waypoints/lib/jquery.waypoints.js');
			await import(/* webpackChunkName: "waypoints" */ 'waypoints/lib/shortcuts/infinite.js');

			var $this = $(this);
			$this.find('a[rel=next]').one('click', function (e) {
				new Waypoint.Infinite({
					element: $this,
					container: $this.find('.columns'),
					items: '.column.is-news-message',
					more: 'a[rel=next]',
					offset: function () {
						return Waypoint.offsetAliases['bottom-in-view'].call(this) + 200;
					},
					onAfterPageLoad: function ($items) {
						// Fade in added items
						const delay = 40;
						$items.css('opacity', 0).each(function (i) {
							var $item = $(this);
							setTimeout(function () {
								$item.css({
									opacity: 1,
									transition: 'opacity ease 120ms'
								});
							}, i * delay);
						});
					}
				});

				$this.find('.pagination-container').hide();
				e.preventDefault();
			});
		});

		// Google Maps
		$('.maps').each(async function () {
			var maps = $(this),
				apiKey = maps.data('api-key'),
				dataGeolocations = maps.data('geolocations').split(';'),
				mapStyle = maps.data('style');

			const loadGoogleMapsApi = (await import(/* webpackChunkName: "load-google-maps-api" */ 'load-google-maps-api')).default;
			const googleMaps = await loadGoogleMapsApi({
				key: apiKey
			});

			var bounds = new google.maps.LatLngBounds();

			var map = new googleMaps.Map(document.querySelector('.maps'), {
				zoom: 17,
				zoomControl: false,
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: false,
				styles: mapStyle
			});

			var image = {
				url: require('../images/pointer.svg').default,
				scaledSize: new google.maps.Size(74, 88),
				origin: new google.maps.Point(0, 0),
				anchor: new google.maps.Point(37, 88)
			};

			for (var i = 0; i < dataGeolocations.length; i++) {
				var latLng = dataGeolocations[i].split(',');

				var marker = new google.maps.Marker({
					position: {
						lat: parseFloat(latLng[0]),
						lng: parseFloat(latLng[1])
					},
					map: map,
					icon: image
				});

				bounds.extend(marker.position);
			}

			// Set zoom bounds are fit
			var zoomChangeBoundsListener;
			google.maps.event.addListener(map, 'zoom_changed', function () {
				zoomChangeBoundsListener =
					google.maps.event.addListener(map, 'bounds_changed', function (event) {
						if (this.getZoom() > 15 && this.initialZoom === true) {
							this.setZoom(8);
							this.initialZoom = false;
						}
						google.maps.event.removeListener(zoomChangeBoundsListener);
					});
			});

			map.initialZoom = true;
			map.fitBounds(bounds);
		});

		// Tabs
		$('.tabs-container').each(function () {
			var $this = $(this);
			var $tabs = $this.find('.tabs li');
			var $togglers = $tabs.children('a[href]');
			var $contents = $togglers.map(function () {
				var id = $(this).attr('href');

				return $(id).get();
			});

			$togglers.each(function (i) {
				var $toggler = $(this);
				var $tab = $($tabs[i]);
				var $content = $($contents[i]);

				$toggler.click(function (e) {
					$tabs.removeClass('is-active');
					$contents.addClass('is-hidden');
					$tab.addClass('is-active');
					$content.removeClass('is-hidden');

					$toggler.blur();
					e.preventDefault();
				});
			});
		});

		// Dropdown
		$('.dropdown').each(function () {
			var dropdown = $(this),
				content = dropdown.find('.dropdown-content'),
				menu = dropdown.find('.dropdown-menu'),
				trigger = dropdown.find('.dropdown-trigger');

			trigger.on('click', function (e) {
				e.preventDefault();

				if (dropdown.hasClass('is-active')) {
					dropdown.removeClass('is-active');
					menu.height(0);
				}
				else {
					dropdown.addClass('is-active');
					menu.height(content.outerHeight());
				}
			});
		});

		// Jump to submitted form
		$('.section:has(form)').each(function () {

			var $this = $(this),
				$form = $this.find('form'),
				$button = $this.find('button'),
				anchor = $this.find('.anchor').attr('name');

			$form.submit(function () {
				if (!$form[0].classList.contains("filter")) {
					$button.prop('disabled', true);
					$button.addClass('is-loading');
				}
			});
		});

		// Lightbox
		$('body:has([rel=lightbox])').each(async function () {
			await import(/* webpackChunkName: "lightgallery" */ 'lightgallery');
			await import(/* webpackChunkName: "lightgallery" */ 'lg-video');

			$(this).lightGallery({
				selector: '[rel=lightbox]',
				download: false,
				thumbnail: false,
				fullScreen: false,
				actualSize: false,
				hash: false,
				videoMaxWidth: '1440px'
			});
		});

		// Toastr
		window.fetch('/umbraco/surface/Toaster/GetToastrMessages')
			.then(res => res.json())
			.then(async json => {
				var toastr = await import('toastr');
				var toastrCount = json.length;

				var cookieName = 'toast',
					ignoredIds = Cookies.get(cookieName),
					ignoreId = function (id) {
						if (ignoredIds) ignoredIds += ',' + id;
						else ignoredIds = id;

						Cookies.set(cookieName, ignoredIds);
					};

				if (toastrCount > 0) {
					for (var i = 0; i < toastrCount; i++) {
						(function () {
							var toastrMessage = json[i];

							if (!ignoredIds || !ignoredIds.includes(toastrMessage.id)) {
								//Set options
								toastr.options.progressBar = true;
								toastr.options.closeButton = true;
								toastr.options.timeOut = 5000;
								toastr.options.extendedTimeOut = 5000;
								toastr.options.showDuration = 0;
								toastr.options.target = 'header';
								toastr.options.titleClass = '';
								toastr.options.messageClass = 'date';

								toastr.info('', '<a href=' + toastrMessage.url + ' data-id="' + toastrMessage.id + '"><strong>' + toastrMessage.title + '</strong><span>' + toastrMessage.text + '</span></a>', {
									onHidden: function () {
										ignoreId(toastrMessage.id);
									}
								}).on({
									click: function (e) {
										e.preventDefault();
										ignoreId(toastrMessage.id);
										document.location = toastrMessage.url;
									}
								});
							}
						})();
					}
				}
			});

		// Search bar large screen
		$('.navbar .navbar-item.is-search').each(function () {
			var element = $(this);
			var searchBar = $('.navbar-menu .search-bar');
			var navbar = $('.navbar-end');

			if (searchBar !== null) {
				element.on('click', function (e) {
					e.preventDefault();

					var top = element.offset().top - 7 + 'px';
					var left = element.offset().left - 10 + 'px';
					var width = navbar.width() - (element.offset().left - navbar.offset().left) + 'px';

					searchBar.css({ top: top, left: left, width: width });
					searchBar.addClass('is-visible');
					var searchInput = document.querySelector('.search-bar.big-screen input');
					if (searchInput) {
						searchBarFocus(searchInput);
					}
				});

				searchBar.find('.is-close').on('click', function (e) {
					e.preventDefault();
					searchBar.css({ width: 56 + 'px' });
					searchBar.removeClass('is-visible');
				});
			}
		});

		async function searchBarFocus(searchInput) {
			await new Promise(resolve => setTimeout(resolve, 250));
			searchInput.focus();
		}

		// Search bar small screen
		$('.search-bar.small-screen').each(function () {
			var element = $(this);
			var breadcrumbs = $('.breadcrumb');
			var top = (122 + breadcrumbs.height()) + 'px';

			element.css({ top: top });

			var buttonClose = element.find('.button.is-close');
			buttonClose.removeClass('is-close');
			buttonClose.addClass('is-search');

			buttonClose.on('click', function () {
				element.toggleClass('is-open');
				buttonClose.toggleClass('is-close');
				buttonClose.toggleClass('is-search');
			});
		});
	});
}